<template>
  <div class="slider-blk">
    <v-slide-group v-model="dateSelected" show-arrows center-active @change="changeDate">
      <v-slide-item v-for="(d, key) in totalDay" :key="key" v-slot="{ active, toggle }">
        <v-btn height="67" :input-value="active" :class="{ 'no-task-day': !dateTasks(d.date) }"
          active-class="font-weight-bold text-color" depressed @click="toggle">
          <div class="d-flex flex-column">
            <span>{{ d.dayName }}</span>
            <span :class="{ 'today': isToday(d) }">{{ d.day }}</span>
            <span>
              <span class="total-tasks" v-if="!isToday(d) && dateTasks(d.date)">
                {{ dateTasks(d.date) }}<span class="task">件</span>
              </span>
            </span>
          </div>
        </v-btn>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    model: null,
    totalDay: [],
  }),
  props: {
    date: {
      required: true,
      default: null
    },
    onlyMe: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters(['tasksByDate']),
    dateSelected: {
      get() {
        return this.totalDay.findIndex(d => d.date == this.date.format('YYYY-MM-DD'))
      },
      set(value) {
        return this.totalDay.findIndex(d => d.date == value)
      }
    },
  },
  methods: {
    isToday(date) {
      return date.date === dayjs().format('YYYY-MM-DD')
    },
    getDateAndMonth() {
      let dateBefore = dayjs().subtract(30, 'day')
      let dateAfter = dayjs().add(dayjs().daysInMonth() - 1, 'day')

      this.totalDay = this.collectDates(dateBefore, dateAfter)

      // let daysInMonth = dayjs().daysInMonth()
      // for (let index = 1; index <= daysInMonth; index++) {
      //   let weekDay = dayjs(dayjs().format('YYYY-MM-') + index).format('ddd')
      //   this.totalDay.push({ date: index, dayName: weekDay })
      // }

      // const year = new Date().getFullYear();
      // const month = new Date().getMonth() + 1;
      // var date = new Date(year, month, 1);
      // while (date.getMonth() === month) {
      //   const weekDay = date.toLocaleDateString("ja-JP", { weekday: "short" });
      //   const dayDate = date
      //     .toLocaleDateString("ja-JP", { day: "numeric" })
      //     .replace("日", "");
      //   this.totalDay.push({ date: dayDate, dayName: weekDay });
      //   date.setDate(date.getDate() + 1);
      // }
    },
    collectDates(startDate, endDate) {
      let dateArray = [];
      let currentDate = dayjs(startDate);
      let stopDate = dayjs(endDate);
      while (currentDate <= stopDate) {
        dateArray.push({
          date: currentDate.format('YYYY-MM-DD'),
          day: currentDate.format('D'),
          dayName: currentDate.format('ddd')
        })

        currentDate = currentDate.add(1, 'days');
      }
      return dateArray;
    },

    changeDate(date) {
      this.$emit('changedate', this.totalDay[date].date)
    },

    dateTasks(date) {
      let count = this.tasksByDate.filter((task) => task.date === date)
      return count.length ? count.length : false
    }
  },
  created() {
    this.getDateAndMonth();
    // this.getTotalTasks()
  }
};
</script>

<style lang="scss" scoped>
.slider-blk .v-btn:not(.v-btn--round).v-size--default {
  min-width: 35px;
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
  /* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
  .slider-blk .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 10.6px;
  }
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
  .slider-blk .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 14.8px;
  }
}

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
  .slider-blk .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 16px;
  }
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  .slider-blk .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 14.6px;
  }
}

.slider-blk {
  ::v-deep {

    .v-slide-group__next,
    .v-slide-group__prev {
      min-width: 15px !important;
    }
  }
}

.slider-blk .v-slide-group__next i,
.slider-blk .v-slide-group__prev i {
  color: #4F4F4F !important;
}

.slider-blk .v-btn__content span:first-child {
  margin: 0 0 0.1rem;
  color: #bdbdbd;
}

.slider-blk .v-btn__content span:last-child {
  color: #828282;
}

.slider-blk .v-btn--active.text-color .v-btn__content span:first-child {
  color: #2f80ed;
}

.slider-blk .v-btn--active.text-color .v-btn__content span:nth-child(2) {
  color: #2f80ed;
}

.slider-blk .v-btn--active.text-color .v-btn__content span:last-child {
  color: #2f80ed;
}

.today {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #4281E5;
  border-radius: 50%;
  color: #fff !important;
  font-size: 0.8rem;
  font-weight: normal;
}

.total-tasks {
  font-weight: 500;
  font-size: 11px;
}

.no-task-day {
  align-items: flex-start;
  padding-top: 3px !important;
}
</style>
