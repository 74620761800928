<template>
  <v-col cols="3" class="text-center mt-2">
    <div class="time-txt">{{ task.task_start_time }}</div>
    <div class="set-blk">集合<br>{{task.gathering_time}}</div>
    <div class="text-center" v-if="weatherInfo">
      <v-avatar size="40" :class="[weatherBackgroundClass, 'mt-2']">
        <v-img :src="weatherInfo" max-height="50" contain />
      </v-avatar>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    task: {
      type: [Object, Array],
      required: true
    }
  },
  computed: {
    weatherInfo() {
      if (!this.task.weather_info) return "";
      return `http://openweathermap.org/img/wn/${this.task.weather_info.weather[0].icon}@4x.png`;
    },
    weatherBackgroundClass() {
      if (!this.task.weather_info) return "";
      return `background-${this.task.time === 0 ? "day" : "night"}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.time-txt {
  font-size: 1.125rem;
  position: relative;
  font-weight: bold;
  color: #000;
  margin: 0 0 0.3rem;
}
.night_icon {
  background: radial-gradient(
    78.85% 78.85% at 50% 50%,
    #8f8686 30.13%,
    rgba(196, 196, 196, 0) 100%
  );
  border-radius: 50%;
}

.set-blk{
  position: relative;
  line-height: 18px;
}

.background {
  &-day {
    background: rgb(87, 165, 253);
    background: linear-gradient(
      0deg,
      rgba(201, 238, 255, 1) 0%,
      rgba(87, 165, 253, 1) 100%
    );
  }

  &-night {
    background: rgb(145, 148, 201);
    background: linear-gradient(
      0deg,
      rgba(225, 226, 239, 1) 0%,
      rgb(145, 148, 201, 1) 100%
    );
  }
}
</style>
