<template>
  <CardBase :help-button="false" :showDate="false">
    <template class="base_card" v-slot:content-body>
      <TaskCardHeader :date="date" :only_me="only_me" @filterTask="filterTask" />
      <div class="ml-n3 mr-n3 mb-6 px-3 bg_color">
        <div v-if="!datesLoading">
          <TaskCalendar :date="date" @changedate="changeDate" :onlyMe="only_me" :key="calenderKey" />
        </div>
        <div v-else>
          <v-skeleton-loader type="list-item, card-heading, divider"></v-skeleton-loader>
        </div>
      </div>

      <div v-if="loading">
        <div v-if="tasks.length > 0">
          <v-skeleton-loader v-for="(task, index) in tasks.length" :key="index" class="mx-auto" max-width="300"
            type="card"></v-skeleton-loader>
        </div>
        <div v-else>
          <v-skeleton-loader class="mx-auto" max-width="300" type="card"></v-skeleton-loader>
          <v-skeleton-loader class="mx-auto" max-width="300" type="card"></v-skeleton-loader>
        </div>
      </div>
      <div v-else>
        <template v-if="tasks.length > 0">

          <div class="night_task_bg_color" v-if="midNightTasks.length > 0">
            <div v-for="(task, index) in midNightTasks" :key="task.id" class="pb-4"
              :class="index === 0 ? 'night-first-task' : ''">
              test
              <div class="mb-4">
                <TaskCard :task="task" />
              </div>
            </div>
          </div>

          <div :class="{ 'day-tasks': midNightTasks.length }">
            <div v-for="(task, index) in dayTasks" :key="task.id" class="mb-4 day_task_bg_color"
              :class="{ 'first-day-task': midNightTasks.length && index === 0 }">
              <TaskCard :task="task" />
            </div>
          </div>

          <div class="night_task_bg_color" v-if="nightTasks.length > 0">
            <div v-for="(task, index) in nightTasks" :key="task.id" class="pb-4"
              :class="index === 0 ? 'night-first-task' : ''">
              <div class="mb-4">
                <TaskCard :task="task" />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <v-row>
            <v-col>
              <p class="text-center text-body-1 mt-5">
                本日はタスクがありません。
              </p>
            </v-col>
          </v-row>
        </template>
      </div>
    </template>
  </CardBase>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import CardBase from "@/components/ui/CardBase";
import { mapGetters } from "vuex";
import TaskCalendar from "@/components/ui/task/taskList/TaskCalendar";
import TaskCard from "@/components/ui/task/taskList/TaskCard";
import TaskCardHeader from "@/components/ui/task/taskList/TaskCardHeader";
export default {
  props: {
    all_task: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      color: "#ffffff",
      date: dayjs(),
      only_me: !this.all_task,
      key: 0,
      calenderKey: 123,
      loading: true,
      totalDay: [],
      datesLoading: false
    };
  },
  components: { CardBase, TaskCalendar, TaskCardHeader, TaskCard },
  computed: {
    ...mapGetters(["tasks"]),
    dayTasks() {
      return [...this.tasks]
        .filter(task => task.time === 0)
        .sort((a, b) => {
          let dateA = dayjs(`${a.date} ${a.task_start_time}`);
          let dateB = dayjs(`${b.date} ${b.task_start_time}`);
          return dateA.isBefore(dateB) ? -1 : 1;
        });
    },
    nightTasks() {
      return [...this.tasks]
        .filter(task => task.time === 1 && Number(task.task_start_time.split(':')[0]) >= 18)
        .sort((a, b) => {
          let dateA = dayjs(`${a.date} ${a.task_start_time}`);
          let dateB = dayjs(`${b.date} ${b.task_start_time}`);
          return dateA.isBefore(dateB) ? -1 : 1;
        });
    },
    midNightTasks() {
      return [...this.tasks].filter(task => task.time === 1 && Number(task?.task_start_time.split(':')[0]) < 5)
        .sort((a, b) => {
          let dateA = dayjs(`${a.date} ${a.task_start_time}`);
          let dateB = dayjs(`${b.date} ${b.task_start_time}`);
          return dateA.isBefore(dateB) ? -1 : 1;
        });
    }
  },
  methods: {
    changeDate(date) {
      let newDay = dayjs(date);
      this.date = newDay;
      this.getMyTask();
    },
    filterTask(value) {
      this.datesLoading = true
      this.only_me = value;
      let dateBefore = dayjs().subtract(30, 'day')
      let dateAfter = dayjs().add(dayjs().daysInMonth() - 1, 'day')

      this.totalDay = this.collectDates(dateBefore, dateAfter)

      let params = {}
      if (this.only_me) {
        params.only_me = true

      }
      params.start_date = this.totalDay[0].date
      params.end_date = this.totalDay[this.totalDay.length - 1].date
      this.$store.dispatch('GET_TOTAL_TASKS_BY_DATE', { params }).then(() => {
        this.datesLoading = false
      });

      this.getMyTask();
    },

    collectDates(startDate, endDate) {
      let dateArray = [];
      let currentDate = dayjs(startDate);
      let stopDate = dayjs(endDate);
      while (currentDate <= stopDate) {
        dateArray.push({
          date: currentDate.format('YYYY-MM-DD'),
          day: currentDate.format('D'),
          dayName: currentDate.format('ddd')
        })

        currentDate = currentDate.add(1, 'days');
      }
      return dateArray;
    },

    getMyTask() {
      this.loading = true
      this.key = this.key + 1
      let params = {};
      params.date = this.date.format("YYYY-MM-DD");
      if (this.only_me) {
        params.only_me = true;
      }

      this.$store.dispatch("GET_ALL_TASKS_BY_DATE_RANGE", params).then(() => {
        this.loading = false
      });
    }
  },
  created() {
    // this.getMyTask();
    this.filterTask(true)
  }
};
</script>

<style lang="scss" src="./List.scss" scoped>
</style>
<style lang="scss">
.night_task_bg_color:after,
.day-tasks:after {
  content: '';
  display: block;
  background-image: url('~@/assets/images/tasks_divider.jpg');
  background-repeat: repeat-x;
  width: 107%;
  height: 33px;
  left: -12px;
  position: absolute;
  top: -15px;
  max-width: 399px;
}

.day-tasks:after {
  background-image: url('~@/assets/images/day_tasks_divider.jpg');
}
</style>
