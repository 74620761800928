<template>
  <v-list two-line class="pt-0 pb-1">
    <template>
      <v-list-item>
        <v-list-item-avatar width="38" height="38" min-width="38" class="header_avatar mt-2 mb-3">
          <img style="object-fit: cover" :src="_image" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="header_title mb-1 font-weight-bold"
            >{{ _name }}
          </v-list-item-title>
          <v-list-item-subtitle class="header_subtitle"
            >{{ _date }}</v-list-item-subtitle
          >
        </v-list-item-content>
        <v-list-item-action>
          <!-- ==========  get all task and user tasks   ============= -->
          <div class="d-flex justify-center align-center">
            <v-menu :rounded="true" offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-icon v-bind="attrs" v-on="on"> mdi-chevron-down</v-icon>
              </template>

              <v-list @change="getTasks">
                <v-list-item link>
                  <v-list-item-title @click="getTasks">
                    すべてのタスク
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-title @click="getMyTask">
                    私のタスク
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <!-- ============================= -->
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TaskHeader",
  data() {
    return {
    };
  },
  props: {
    date: {
      required: true
    },
    only_me: {
      required: true
    }
  },
  computed: {
    ...mapGetters(["user"]),
    _image() {
      if (this.only_me) {
        return this.user.profile_image
          ? this.user.profile_image_url
          : `${process.env.VUE_APP_ROOT_API}images/userblank.png`;
      } else {
        return require('@/assets/images/sk-logo-avatar.png')
      }
    },
    _name() {
      return this.only_me ? this.user.full_name + ' さんのタスク' : '全てのタスク'
    },
    _date() {
      return this.date.format('YYYY年MM月DD日（ddd）')
    }
  },
  methods: {
    getTasks() {
      this.$emit('filterTask', false)
    },
    getMyTask() {
      this.$emit('filterTask', true)
    }
  }
};
</script>
<style lang="scss" scoped>
.header_avatar {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.45);
}
.mdi-view-sequential::before {
  content: "\F728";
  color: #2f80ed;
}
.header_title {
  font-size: 0.875rem;
  color: #333333;
}
.header_subtitle {
  font-size: 0.875rem;
  color: #4f4f4f !important;
}
</style>
