<template>
  <v-row @click="goToDetail(task.id)" dense>
    <TaskLeftSide :task="task" />
    <TaskRightSide :task="task" @goToDetail="goToDetail" />
  </v-row>
</template>

<script>
import TaskLeftSide from "./TaskLeftSide";
import TaskRightSide from "./TaskRightSide";
export default {
  props: {
    task: {
      type: [Object, Array],
      required: true
    }
  },
  components: { TaskLeftSide, TaskRightSide },
  methods: {
    goToDetail(id) {
      this.$router.push("/task/" + id);
    }
  }
};
</script>
