<template>
  <v-col cols="9">
    <v-card
      class="custome_border"
    >
      <div class="py-5 px-6" :style="{
        backgroundColor: task.service.background_color,
        color: task.service.font_color
      }">
        <v-card-text class="pa-0 mb-2">
          <div class="font-weight-bold calender-title">
            {{ task.site.site_id }} {{ task.site.name }}
          </div>
          <div class="d-flex" v-if="task.site.prefecture || task.site.city || task.site.address">
            <v-icon :color="task.service.font_color" small>mdi-map-marker</v-icon> 
            <span class="ml-1 address-txt text-truncate">{{ task.site.prefecture ? task.site.prefecture.name : '' }}{{ task.site.city }}{{ task.site.address }}</span>
          </div>
          <div v-if="task.car" class="d-flex">
            <v-icon :color="task.service.font_color" small>mdi-car</v-icon>
            <span class="ml-1 more-info"> {{ task.car.car_class }}
            {{ task.car.number_plate }}</span>
          </div>
          <div class="service-name">
            {{ task.service ? task.service.name : "" }}
          </div>
          <div class="partner-name">
            <template v-for="item in task.task_freelancer_users">
              {{ item.name }} ({{ item.pivot.freelancers_count }})
            </template>
          </div>
        </v-card-text>
        <v-card-actions class="pa-0 flex-wrap">
          <div
            class="profile-blk"
            v-for="(member, index) in _taskMember"
            :key="`member-${member.full_name}-${index}`"
          >
            <v-avatar size="42" :class="isLeader(member) ? 'active' : ''">
              <img :src="getUserPhoto(member)" :alt="`${member.full_name}-avatar`"/>
            </v-avatar>
            <div class="truncate">{{ member.last_name }}</div>
          </div>
        </v-card-actions>
        <v-card-text class="pa-0 mb-2 red--text">
          <span>
          {{task.important_notice | truncate(30, '...')}}
          </span>
        </v-card-text>
      </div>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    task: {
      type: [Object, Array],
      required: true
    }
  },
  computed: {
    _taskMember() {
      if (!this.task.task_member) {
        return []
      }
      let members = []
      if (this.task.leader) members.push(this.task.leader)
      members.push(...this.task.task_member.filter(member => member.id !== this.task.leader_id))
      return members
    }
  },
  methods: {
    getUserPhoto(member) {
      // return member.profile_image
      //     ? member.profile_image_url
      //     : `${process.env.VUE_APP_ROOT_API}images/userblank.png`;
      return member.profile_image_url
          ? member.profile_image_url
          : `${process.env.VUE_APP_ROOT_API}images/userblank.png`;
    },
    isLeader(member) {
      return this.task.leader_id && this.task.leader_id === member.id
    },
    goToAddress(task) {
      window.open(`https://www.google.com/maps?q=${ task.site.prefecture ? task.site.prefecture.name : '' } ${ task.site.city } ${ task.site.address }`, '_blank')
    }
  }
};
</script>

<style lang="scss" scoped>
.calender-title{
  font-size: 0.938rem;
}
.active {
  border: 3px solid #f2994a;
}
.custome_border {
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%) !important;
  border-radius: 10px !important;
}
.address-txt{
  font-size: 0.75rem;
  max-width: 224px;
  display: inline-block;
  vertical-align: middle;
}
.partner-name {
  font-size: 0.75rem;
}
.more-info{
  font-size: 0.75rem;
  display: inline-block;
  vertical-align: middle;
}
.service-name{
  font-size: 0.875rem;
}

.profile-blk{
  text-align: center;
  margin: 0 0.2rem 0.2rem 0;
}
.truncate {
    max-width: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.625rem;
    margin: 0 auto;
}
</style>
